import Loading from '@/components/Loading';
import { UseParseError } from '@/features/helpers/fiiErrorParser';
import kratosApi from '@/features/kratos';
import KratosInputs from '@/features/kratos/KratosForm';
import { useForm } from '@mantine/form';
import {
  UpdateLoginFlowWithPasswordMethod,
  UpdateLoginFlowWithTotpMethod,
} from '@ory/kratos-client';
import { useCallback, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mantine/core';
import '@/scss/components/Login.css';
import '@/scss/components/Form.css';
import UseNotifications from '@/features/helpers/useNotifications';
import { useTranslation } from 'react-i18next';
import groupNodes from '@/features/kratos/getGroups';
import { useAppSelector } from '@/app/hooks';
import { selectSession } from '@/features/kratos/kratosSlice';

export default function Login({ aal2 }: { aal2?: boolean }) {
  const navigate = useNavigate();
  const notification = UseNotifications();
  const { t } = useTranslation();
  const session = useAppSelector(selectSession);

  const {
    data: flow,
    error: flowError,
    isLoading: flowIsLoading,
    refetch,
  } = kratosApi.useGetLoginQuery(aal2, {
    pollingInterval: 1000 * 60 * 5,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  const [logOut] = kratosApi.useLazyLogoutQuery();

  const [logIn, { error: loginError, isLoading: loginIsloading }] =
    kratosApi.usePostLoginMutation();

  const isLoading = useMemo(
    () => flowIsLoading || loginIsloading || session.status === 'pending',
    [flowIsLoading, loginIsloading, session]
  );

  const { message, code } = UseParseError(flowError || loginError);
  const formApi = useForm<UpdateLoginFlowWithPasswordMethod & { flow: string }>();

  const onSubmit = useCallback(
    (values: typeof formApi.values) => {
      if (flow) {
        const pinValues = values as unknown as UpdateLoginFlowWithTotpMethod;
        if (
          (pinValues.method === 'totp' && pinValues.totp_code?.length === 6) ||
          pinValues.method !== 'totp'
        ) {
          formApi.setFieldValue('totp_code', undefined);
          logIn({ ...values, flow: flow.id });
        }
      }
    },
    [formApi, flow, logIn]
  );

  useEffect(() => {
    if ((formApi.values as unknown as UpdateLoginFlowWithTotpMethod)?.totp_code?.length === 6) {
      onSubmit(formApi.values);
    }
  }, [formApi.values, onSubmit]);

  useEffect(() => {
    if (message) {
      notification.show({
        title: t('login.error'),
        message,
        color: 'yellow',
      });
    }
  }, [message, notification, t]);

  useEffect(() => {
    if (code === 4000010) {
      navigate('/verification');
    }
  });

  const form = useMemo(() => {
    if (flow) {
      const groups = groupNodes(flow.ui.nodes);
      const group = groups.find((group) => group.every((node) => node.group !== 'lookup_secret'));
      return group
        ? KratosInputs({
            group,
            refetch: aal2 ? undefined : refetch,
            setter: formApi.setFieldValue,
          })
        : undefined;
    } else return undefined;
  }, [flow, formApi.setFieldValue, aal2, refetch]);

  if (!flow) return <Loading overlayOpacity={0} />;

  // #1137 Invalid OTP config or form
  if (!form && aal2) {
    return (
      <>
        <div className="titles">
          <h1>
            <strong>{t('boarding_page.login.missing_otp.title')}</strong>
          </h1>
          <p>{t('boarding_page.login.missing_otp.description')}</p>
          <Link
            className="cancel"
            onClick={() => {
              logOut();
            }}
            to="/"
          >
            {t('boarding_page.login.abort')}
          </Link>
        </div>
      </>
    );
  }

  return (
    <>
      <Loading visible={isLoading} />
      <div className="login">
        <div className="titles">
          <h1>
            <strong>
              {t(aal2 ? 'boarding_page.login.aal2.title' : 'boarding_page.login.aal1.title')}
            </strong>
          </h1>
          <h5>
            {t(aal2 ? 'boarding_page.login.aal2.subtitle' : 'boarding_page.login.aal1.subtitle')}
          </h5>
        </div>
        <form
          className={`form ${aal2 && message ? 'invalid-pin' : ''}`}
          onSubmit={formApi.onSubmit(onSubmit)}
        >
          {form}
          {!aal2 && (
            <div className="links">
              <Link to="/recover">{t('boarding_page.login.aal1.forgot_button')}</Link>
            </div>
          )}
          <Button type="submit" fullWidth={true}>
            {t(
              aal2
                ? 'boarding_page.login.aal2.submit_button'
                : 'boarding_page.login.aal1.submit_button'
            )}
          </Button>
        </form>

        {aal2 && (
          <>
            <Link className="use-recovery" to="/two-factor-recovery">
              {t('boarding_page.login.aal2.forgot_button')}
            </Link>
            <Link
              className="cancel"
              onClick={() => {
                logOut();
              }}
              to="/"
            >
              {t('boarding_page.login.abort')}
            </Link>
          </>
        )}
      </div>
    </>
  );
}
