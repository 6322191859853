import {
  de_default
} from "./chunk-EAMWJAQB.mjs";
import {
  en_default
} from "./chunk-RSR2DOPA.mjs";
import {
  fr_default
} from "./chunk-AZHTPCGC.mjs";

// src/frontend/index.ts
var frontend_default = { en: en_default, fr: fr_default, de: de_default };

export {
  frontend_default
};
